function cookieCnil(){
    if ($.cookie('cookieCnil') === undefined) {
        $('#cookieCnil').fadeIn();

        $(window).click(function () {
            $('#cookieCnil').fadeOut(300);
            $.cookie('cookieCnil', "viewed", {expires: 365});
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > 1) {
                $('#cookieCnil').fadeOut(300);
                $.cookie('cookieCnil', "viewed", {expires: 365});
            }
        });
    }
}

$(function () {
    cookieCnil(); // Cookie CNIL

    $('#burger').click(function () {
        $('#mobile-nav').fadeIn("slow");
    });
    $('#burger-close').click(function () {
        $('#mobile-nav').fadeOut("slow");
    });

    $('#credit-img span').hide();
    $('#credit-img span:nth-of-type(1)').show();
});

//Hero > Type writer animation
var TxtType = function(el, toRotate, bg, period) {
    this.toRotate = toRotate;
    this.bg = bg;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;

        // Change background
        var hero = document.getElementById('hero');
        if (i+1 == this.toRotate.length) {
            hero.style.backgroundImage = "url('" + this.bg[0] + "')";
            $('#credit-img span').hide();
            $('#credit-img span:nth-of-type(1)').show();
        } else {
            hero.style.backgroundImage = "url('" + this.bg[i + 1] + "')";
            $('#credit-img span').hide();
            var countCredit = i + 2;
            $('#credit-img span:nth-of-type('+countCredit+')').show();
        }

    }

    setTimeout(function() {
        that.tick();
    }, delta);
};

window.onload = function() {
    var elements = document.getElementsByClassName('typewrite');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        var bg = elements[i].getAttribute('data-bg');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), JSON.parse(bg), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
};
