$(function () {
    var gformInput = $(".gfield input");
    var gformTextarea = $(".gfield textarea");

    $('#field_1_1').find('label').hide();
    $('#field_1_2').find('label').hide();

    // Change position label if input/textarea value
    gformInput.each(function() {
        if($(this).val()) {
            $(this).closest('.gfield').find('.gfield_label').css('bottom', '30px');
            $(this).closest('.gfield').find('.gfield_label').css('font-size', '10px');
        }
    });
    gformInput.focus(function(){
        $(this).closest('.gfield').find('.gfield_label').css('bottom', '30px');
        $(this).closest('.gfield').find('.gfield_label').css('font-size', '10px');
    });
    gformInput.focusout(function(){
        if(!$(this).val()) {
            $(this).closest('.gfield').find('.gfield_label').css('bottom', '10px');
            $(this).closest('.gfield').find('.gfield_label').css('font-size', '12px');
        }
    });
    gformInput.change(function(){
        if($(this).val()) {
            $(this).closest('.gfield').find('.gfield_label').css('bottom', '30px');
        } else {
            $(this).closest('.gfield').find('.gfield_label').css('bottom', '10px');
        }
    });
    gformTextarea.focus(function(){
        $(this).closest('.gfield').find('.gfield_label').css('top', '-15px');
        $(this).closest('.gfield').find('.gfield_label').css('font-size', '10px');
    });
    gformTextarea.focusout(function(){
        if(!$(this).val()) {
            $(this).closest('.gfield').find('.gfield_label').css('top', '0');
            $(this).closest('.gfield').find('.gfield_label').css('font-size', '12px');
        }
    });

    // Validation form
    $(".gfield_error").each(function() {
        var message = $(this).find('.validation_message').text();

        $(this).find('label').append(' <span class="error-msg">(' + message + ')</span>');
        $(this).find('label').css('color', '#f36c6a');
    });

    gformInput.on("change", function() {
        $(this).closest('.gfield').find('.gfield_label').css('color', '#fff');
        $(this).closest('.gfield').find('.gfield_label .error-msg').hide();
    });

    gformTextarea.on("change", function() {
        $(this).closest('.gfield').find('.gfield_label').css('color', '#fff');
        $(this).closest('.gfield').find('.gfield_label .error-msg').hide();
    });
});